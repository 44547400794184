import React from "react"
import { Link, withPrefix } from "gatsby"
import Layout from "@layout/layout"
import { pl, en } from "@content/pages/local.yaml"
import HeaderSubpage from "@layout/header-subpage"
import SEO from "@layout/seo"

export default ({isEn}) => {
  const translation = isEn ? en : pl

  return (
    <Layout isEn={isEn}>
      <SEO
        isEn={isEn}
        title={translation.metaTitle}
        description={translation.metaDescription}
      />
      <HeaderSubpage isEn={isEn}/>
      <section className="section wrapper">
        <div
          className="padding sasaboxbg text-center zb-lazy  b-loaded"
          style={{
            backgroundImage: `url(${withPrefix("images/local/hero.png")})`,
          }}
        >
          <div className="row collapse">
            <div className="large-12 columns text-center">
              <h1 style={{ color: "#ffffff" }}>{translation.title}</h1>
            </div>
          </div>
        </div>
      </section>
      <section
        className="news-text padding wow fadeInUp"
        data-wow-duration="1.5s"
        style={{
          visibility: "hidden",
        }}
      >
        <div className="row">
          <div className="large-8 large-centered medium-12 small-12 columns">
            <p>
              <a
                href={withPrefix("images/local/bialeniedziele1.jpg")}
                className="image-link"
              >
                <img
                  src={withPrefix("images/local/bialeniedziele1.jpg")}
                  alt=""
                />
              </a>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="large-8 large-centered medium-12 small-12 columns">
            <p>
              <span style={{ fontWeight: 400 }}>
                Nasze działania są skierowane również do Polaków. Wiemy jak
                istotna dla społeczeństwa jest edukacja i profilaktyka
                zdrowotna.{" "}
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Białe Niedziele</strong>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Pomysł na Białe Niedziele zaczerpnęliśmy z tradycji PRL-u,
                podczas której organizowano wyjazdy personelu medycznego do
                małych miejscowości, aby przeciwdziałać chorobom.
              </span>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="large-8 large-centered medium-12 small-12 columns">
            <p>
              <a
                href={withPrefix("images/local/bialeniedziele2.jpg")}
                className="image-link"
              >
                <img
                  src={withPrefix("images/local/bialeniedziele2.jpg")}
                  alt=""
                />
              </a>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="large-8 large-centered medium-12 small-12 columns">
            <p>
              <span style={{ fontWeight: 400 }}>
                My również chcemy o to zadbać – zbadać, wykryć i zdiagnozować.
                Staramy się dotrzeć do miejscowości lub wsi, które nie posiadają
                własnego szpitala, a dostęp do opieki medycznej jest utrudniony.
                We współpracy z lokalnymi władzami, staramy się przebadać jak
                największą liczbę osób. Dla pacjenta – jest to czasami jedyna
                możliwość konsultacji specjalistycznej. Dla młodego lekarza –
                pierwsza okazja do współpracy z pacjentem pod nadzorem starszych
                kolegów.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Apteczka Podróżnika</strong>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Organizujemy spotkania z lekarzami z konkretnych specjalizacji,
                którzy dzielą się swoją wiedzą ze słuchaczami. Staramy się
                edukować osoby wybierające się na dłuższe pobyty w bardziej
                egzotyczne zakątki świata. Chcesz być na bieżąco? Śledź nas na{" "}
              </span>
              <a href="https://www.facebook.com/sasafoundation/?fref=ts">
                <span style={{ fontWeight: 400 }}>Facebooku</span>
              </a>
              <span style={{ fontWeight: 400 }}>!</span>
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}
